import React, { useState, createContext,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as noScroll from "no-scroll";
export const ModalContext = createContext();

function Modal({ toggle, handleClose, children }) {
    if (!toggle) return null;

    return children;
}

function ModalManager({ modalTypes, children }) {
    const [togglePopup, setTogglePopup] = useState(false);
    const [modalProps, setModalProps] = useState({});
    const [currentModalType, setCurrentModalType] = useState(null);
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState(null);

    useEffect(() => {
      if (prevLocation && location.pathname !== prevLocation.pathname && togglePopup) {
        setTogglePopup(false);
      }
      setPrevLocation(location);
    }, [location, prevLocation, togglePopup]);

    const openModal = (type, props = {}) => {
        setCurrentModalType(type);
        setModalProps(props);
        setTogglePopup(true);
    };

    const closeModal = () => {
        setCurrentModalType(null);
        setModalProps({});
        setTogglePopup(false);
        noScroll.off();
    };

    const renderModalContent = () => {
        const ModalComponent = modalTypes[currentModalType];
        if (ModalComponent) {
            return <ModalComponent {...modalProps} handleClose={closeModal}  toggle={togglePopup}/>;
        }
        return null;
    };

    return (
        <ModalContext.Provider value={{ togglePopup, openModal, closeModal }}>
            {children}
            <Modal toggle={togglePopup} handleClose={closeModal}>
                {renderModalContent()}
            </Modal>
        </ModalContext.Provider>
    );
}

export default ModalManager;
