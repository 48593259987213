function response(res) {
    if (res.data && res.data && res.data._reload) {
        window.location.reload();
    }
    return res;
}

const actionsInterceptor = {
    response
}

export default actionsInterceptor;