export function request(req){
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    if (token != null) {
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
}

export function response(res) {
    if (res.data && res.data && res.data.token) {
        // console.log("token", process.env.REACT_APP_TOKEN_NAME, process.env);
        localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, res.data.token);

    }
    return res;
}

const tokenInterceptor = {
    request,
    response
};

export default tokenInterceptor;