import qs from "qs";
import {apiVoissa} from "../../libs/api";
import moment from "moment";
import { trancheAge } from "../../libs/helper";
import { config as globalConfig } from "../../config";
//import {fetchCurrentUser as oldFetchCurrentUser} from "../../creators/currentUser";
//import store from "../../store";


export const signupUser = (data = {}, config = {}) => apiVoissa.post(`/users`, data, config);
export const loginUser = (data = {}, config = {}) => apiVoissa.post(`/users/login`, data, config);


export const fetchConfirmEmail = (data = {}, config = {}) => apiVoissa.post(`/users/accounts/verify-email`, data, config);
export const fetchConfirmAccount = (data = {}, config = {}) => apiVoissa.post(`/users/accounts/verify-account`, data, config);
export const verifyToken = ({ ephemeralToken = null, currentToken = null}, config = {}) => apiVoissa.post(`/users/accounts/verify-token`, {ephemeralToken, currentToken}, config)
export const forgotPasswordUser = (data = {}, config = {}) => apiVoissa.post(`/users/accounts/forgot-password`, data, config);
export const resetPassword = (data = {}, config = {}) => apiVoissa.post(`/users/accounts/reset-password`, data, config);
export const updateCurrentUserRightModerationTag = (config = {}) => apiVoissa.patch(`/users/me/update-right-moderation-tag`, '', config);


//CURRENT USER

export const fetchUnreadRooms = (config = {}) => apiVoissa.get(`/users/me/rooms/unread`, config);
export const fetchUnreadNotifications = (config = {}) => apiVoissa.get(`/users/me/notifications/unread`, config);

export const requestSuppressionCurrentUser = (data = {}, config = {}) => apiVoissa.patch(`/users/me/request-suppression`, data, config);
export const cancelRequestSuppressionCurrentUser = (config = {}) => apiVoissa.get(`/users/me/cancel-request-suppression`, config);
export const deleteCurrentUser = (config = {}) => apiVoissa.delete(`/users/me/confirm-suppression`, config);

export const fetchUserIgnored = (query = {}, config = {}) => apiVoissa.get(`/users/me/users-ignored?${qs.stringify(query)}`, config);
export const createUserIgnored = (userId, config = {}) => apiVoissa.post(`/users/me/users-ignored/${userId}`, '', config);
export const deleteUserIgnored = (userId = {}, config = {}) => apiVoissa.delete(`users/me/users-ignored/${userId}`, config);

export const fetchMediaCommentsModeration = (kind = "medias", query = {}, config = {}) => apiVoissa.get(`/users/me/moderation-comments/${kind}?${qs.stringify(query)}`, config);

export const updateCurrentUserPassword = (config = {}) => apiVoissa.post(`/users/me/change-password`, '', config);
export const updateCurrentUserEmail = (data = {}, config = {}) => apiVoissa.patch(`/users/me/update-email`, data, config);
export const updateCurrentUserNotification = (data, config = {}) => apiVoissa.patch(`/users/me/update-notifications`, data, config);

export const updateCurrentUser = (data, config = {}) => apiVoissa.patch(`/users/me`, data, config);

export const updateEmailCurrentUser = (data = {}, config = {}) => apiVoissa.patch(`/users/me/update-emailunconfirmed`, data, config);
export const sendEnrollmentEmailCurrentUser = (data = {}, config = {}) => apiVoissa.patch(`/users/me/send-emailunconfirmed`, data, config);

export const fetchCommentsToModerate = (type, parentId, query, config = {}) => apiVoissa.get(`/users/me/moderation-comments/${type}/${parentId}?${qs.stringify(query)}`, config);

export const fetchCurrentUserAbo = (query = {}, config = {}) => apiVoissa.get(`/users/me/abo`, config);
export const fetchCurrentUserSubscription = (query = {}, config = {}) => apiVoissa.get(`/users/me/subscription`, config);

export const fetchCurrentUserHasActiveSubscription = (query = {}, config = {}) => apiVoissa.get(`/users/me/subscriptions/is-active`, config);

export const fetchCountMessenger = (config = {}) => apiVoissa.get(`/users/me/count-messenger`, config);

function calculateAge(birthdate) {
    try{
        const birthDate = moment(birthdate);
    const today = moment();
    return today.diff(birthDate, 'years');
    }
    catch(e){
        return 0;
    }
}

function trackCurrentUser(currentUser){
    try {
        const age1 = calculateAge(currentUser?.profile?.informations[0]?.birthday);
        const age2 = calculateAge(currentUser?.profile?.informations[1]?.birthday);
        const age_average = age2 > 0 ? Math.round((age1 + age2) / 2) : age1;
        const trackingProps = {
            user_id: currentUser?._id, 
            logged_in: true, 
            subscribed: currentUser?.subscription?.hasAccess,
            certified: currentUser?.isCertified,
            age_avg_range: trancheAge(age_average, false),
            age1_range : trancheAge(age1, false),
            age2_range : trancheAge(age2, false),
            gender_str: globalConfig.genderList[currentUser?.profile?.gender || 0], 
        };
        const scriptTag = document.querySelector('script[data-domain="voissa.com"]');
        if(!scriptTag.getAttribute('event-logged_in')) window.plausible('pageview', {props: trackingProps});
        Object.keys(trackingProps).map(key=>scriptTag.setAttribute(`event-${key}`, trackingProps[key]))
    }
    catch(e) {return false};
}

export const fetchUserCurrent = async (config) => {
    const currentUser = await apiVoissa.get(`/users/me`, config);
    if(currentUser?._id) trackCurrentUser(currentUser);
    return currentUser;
};

// USER

export const fetchUsersStaff = (query = {}, config = {}) =>apiVoissa.get(`/users?staff=true`, config);
export const fetchUsersMeet = (query = {}, config = {}) =>apiVoissa.get(`/meets/users?${qs.stringify(query)}`, config);

export const fetchUsers = (query = {}, config = {}) =>apiVoissa.get(`/users?${qs.stringify(query)}`, config);

export const searchUsers = (data = {}, config = {}) =>apiVoissa.post(`/users/search`, data, config);
export const fetchUser = (userId, config = {}) => apiVoissa.get(`/users/${userId}`, config);
export const setCountViewUser = (userId, config = {}) => apiVoissa.patch(`/users/${userId}/view`, '', config);

export const fetchUserTimelines = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/feed?${qs.stringify(query)}`, config);


export const fetchUserWarnings = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/warnings?${qs.stringify(query)}`, config);

export const createUserWarning = (userId, data = {}, config = {}) => apiVoissa.post(`/users/${userId}/warnings`, data, config);
export const updateUserWarningAccepted = (userId, warningId = null, config = {}) => apiVoissa.patch(`/users/${userId}/warnings/${warningId}/accepted`, {}, config);
export const deleteUserWarningAccepted = (userId, warningId = null, config  ={}) => apiVoissa.delete(`/users/${userId}/warnings/${warningId}`, config);

export const fetchUserRelationCounts = (userId, query = {}, config) => apiVoissa.get(`/users/${userId}/relations/count`, config);
export const fetchUserFriends = (userId, query = {}, config) => apiVoissa.get(`/users/${userId}/relations/friends?${qs.stringify(query)}`, config);

export const fetchUserMemberCircle = (userId, query = {}, config) => apiVoissa.get(`/users/${userId}/relations/member-circle?${qs.stringify(query)}`, config);
export const fetchUserStateCircle = (userId, config) => apiVoissa.get(`/users/${userId}/relations/state-circle`, config);

//export const fetchUserFriendsRequest = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/relations/friend-requests?${qs.stringify(query)}`, config);
//export const createUserFriendsRequest = (userId, data = {}, config = {}) => apiVoissa.post(`/users/${userId}/relations/friend-requests`, data, config);
export const fetchUserRandomQuestions = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/random-questions?${qs.stringify(query)}`, config);

//export const acceptedUserFriendRequest = (userId, answerId, config = {}) => apiVoissa.patch(`/users/${userId}/relations/friend-requests/${answerId}/accepted`, {}, config);
//export const refusedUserFriendRequest = (userId, answerId, config = {}) => apiVoissa.patch(`/users/${userId}/relations/friend-requests/${answerId}/refused`, {}, config);

//export const updateUserFriendFavorite = (userId, friendId = null, config = {}) => apiVoissa.patch(`/users/${userId}/relations/friends/${friendId}/update-favorite`, {}, config);
export const deleteUserFriend = (friendId = null, config = {}) => apiVoissa.delete(`/users/me/relations/friends/${friendId}`, config);
export const createUserFriend = (data = {}, config = {}) => apiVoissa.post(`/users/me/relations/friends/`, data, config);

export const fetchUserStatus = (userId, statusId, query = {}, config = {}) => apiVoissa.get(userId ? `/users/${userId}/status/${statusId}?${qs.stringify(query)}` : `/status/${statusId}?${qs.stringify(query)}`, config);
export const createUserStatus = (userId, data, config = {}) => apiVoissa.post(`/users/${userId}/status/`, data, config);
export const updateUserStatus = (userId, statusId, data = {}, config = {}) => apiVoissa.patch(`/users/${userId}/status/${statusId}`, data, config);
export const deleteUserStatus = (userId, statusId = {}, config = {}) => apiVoissa.delete(`/users/${userId}/status/${statusId}`, config);

export const fetchUserNotificationStatus = (userId, notificationId, config = {}) => apiVoissa.get(`users/${userId}/notifications/${notificationId}/status`,config);

export const fetchUserNotifications = (query = {}, config = {}) => apiVoissa.get(`/users/me/notifications?${qs.stringify(query)}`, config);
export const markUserNotificationOpen = (notificationId = null, config ={}) => apiVoissa.patch(`/users/me/notifications/open/${notificationId}`, {}, config);
export const markUserAllNotificationOpen = (config ={}) => apiVoissa.patch(`/users/me/notifications/open`, {}, config);

export const fetchUserArticles = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/articles?${qs.stringify(query)}`, config);

export const fetchUserAlbums = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/albums?${qs.stringify(query)}`, config);

export const fetchUserAlbum = (userId, albumId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/albums/${albumId}?${qs.stringify(query)}`, config);
export const createUserAlbum = (userId, data, config = {}) => apiVoissa.post(`/users/${userId}/albums`, data, config);
export const updateUserAlbum = (userId, albumId, data, config = {}) => apiVoissa.patch(`/users/${userId}/albums/${albumId}`, data, config);
export const deleteUserAlbum = (userId, albumId = {}, config = {}) => apiVoissa.delete(`/users/${userId}/albums/${albumId}`, config);

export const fetchUserProfileMedias = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/profile-medias?${qs.stringify(query)}`);

export const followingUser = (userId, profilId, config = {}) => apiVoissa.get(`/users/${userId}/relations/followers/${profilId}`, config);
export const createFollowingUser = (userId, data = {}, config = {}) => apiVoissa.post(`/users/${userId}/relations/following-profile`, data, config);
export const deleteFollowingUser = (userId, profileId, config = {}) => apiVoissa.delete(`/users/${userId}/relations/following-profile/${profileId}`, config);

export const fetchUserFollowings = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/relations/followings?${qs.stringify(query)}`, config);
export const fetchUserFollowers = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/relations/followers?${qs.stringify(query)}`, config);

export const reportUser = (userId, data = {}, config = {}) => apiVoissa.post(`/users/${userId}/reporting`, data, config);

export const fetchUserByIp = (data = {}, config = {}) => apiVoissa.post(`/users/find-ip`, data, config);

export const fetchUserLastVisitors = (userId, query = {}, config = {}) => apiVoissa.get(`/users/${userId}/last-visitors?${qs.stringify(query)}`, config);

export const fetchUserSessions = (userId = null, config = {}) => apiVoissa.get(`/users/${userId}/sessions-group`, config);

export const fetchUserBookmarksMedias = (query = {}, config = {}) => apiVoissa.get(`/users/me/bookmarks/medias?${qs.stringify(query)}`, config);

export const fetchUserTokenVMessenger = (data = {}, config = {}) => apiVoissa.post(`/users/me/login-vmessenger`, data, config);

export const fetchUserPostsThreads = (userId = null,query = {}, config = {}) => apiVoissa.get(`/users/${userId}/threads?${qs.stringify(query)}`, config);
export const fetchUserPostsThreadsResponses = (userId = null,query = {}, config = {}) => apiVoissa.get(`/users/${userId}/responses-threads?${qs.stringify(query)}`, config);


export const USER_TOKEN_KEY = `user-token`;
export const USER_SET_COUNT_VIEW = `user-set-count-view`;
export const USER_CURRENT_KEY = `user-current`;
export const USER_KEY = `user`;
export const USER_UNREAD_ROOMS_KEY = `user-unread-rooms`;
export const USER_UNREAD_NOTIFICATIONS_KEY = `user-unread-notifications`;

export const USER_CURRENT_IGNORE_KEY = `user-current-users-ignore`;
export const USER_CURRENT_COMMENTS_MODERATION_KEY = `user-current-comments-moderation`;
export const USER_CURRENT_CLICK_CTA_KEY = `user-current-click-cta`;

export const USER_CURRENT_MODERATION_COMMENTS_MEDIAS_KEY = `user-current-moderation-comments-medias`;
export const USER_CURRENT_MODERATION_COMMENTS_ARTICLES_KEY = `user-current-moderation-comments-articles`;

export const USER_WARNINGS_KEY = `user-warnings`;
export const USER_RELATION_COUNT_KEY = `user-relations-count`;
export const USER_RELATION_STATE_CIRCLE_KEY = `user-relations-state-circle`;
export const USER_MEMBER_CIRCLE_KEY = `user-member-circle`;
export const USER_FRIENDS_KEY = `user-friends`;
export const USER_FRIENDS_SEARCH_KEY = `user-friends-search`;

export const USER_CIRCLE_SEARCH_KEY = `user-circle-search`;

export const USER_STATUS_KEY = `user-status`;
export const USER_NOTIFICATION_STATUS_KEY = `user-notification-status`;
export const USER_NOTIFICATIONS_KEY = `user-notifications`;
export const USER_ARTICLES_KEY = `user-articles`;
export const USER_THREADS_KEY = `user-threads`;
export const USER_THREADS_RESPONSES_KEY = `user-threads-responses`;
export const USER_ALBUMS_KEY = `user-albums`;
export const USER_ALBUM_KEY = `user-album`;

export const USER_PROFILE_MEDIAS_KEY = `user-profile-medias`;
export const USER_BEST_VIDEOS_KEY = `user-best-videos`;
export const USER_BEST_PHOTOS_KEY = `user-best-photos`;

export const USER_FIL_ACTUALITY_KEY = `user-fil-actuality`;

export const USER_FOLLOWER_KEY = `user-follower`;

export const USER_FOLLOWERS_KEY = `user-followers`;
export const USER_FOLLOWINGS_KEY = `user-followings`;
export const USER_LAST_VISITORS_KEY = `user-last-visitors`;

export const USER_LIST_USERS_BY_IP_KEY = `user-list-users-by-ip`;

export const USER_LIST_SESSION_KEY = `user-list-session`;
export const USER_BOOKMARKS_MEDIAS_KEY = `user-bookmarks-medias`;

export const USERS_KEY = `users`;
export const USERS_STAFF_KEY = `users-staff`;
export const USERS_MEET_KEY = `users-meet`;

export const USER_CURRENT_COUNT_MESSENGER_KEY = `user-current-count-messenger`;