import {apiVoissa} from "../../libs/api";
import qs from "qs";


export const fetchTagsProtagonist = (query = {}, config = {}) => apiVoissa.get(`/tags-list/protagonist/childrens?${qs.stringify(query)}`, config);
export const fetchTags = (query = {}, config = {}) => apiVoissa.get(`/tags?${qs.stringify(query)}`, config);
export const fetchTag = (id, config ={}) => apiVoissa.get(`/tags/${id}`, config);

export const fetchTagsWarning = (query = {}, config = {}) => apiVoissa.get(`/tags-list/warning/childrens?${qs.stringify(query)}`, config);

export const fetchTagsMedia = (query = {}) => apiVoissa.get(`/tags-list/contentType/childrens?${qs.stringify(query)}`);

export const TAGS_PROTAGONIST_KEY = `tags-protagonist`;
export const TAGS_WARNING_KEY = `tags-warning`;
export const TAGS_MEDIA_KEY = `tags-media`;
export const TAGS_KEY = `tags`;
export const TAG_KEY = `tag`;