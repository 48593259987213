import React from "react";
import "./AlertModal.scss";
import Modal from "../../components/Modal";
import {Button} from "../../components/Button";

export const AlertModal = ({
        title = "",
        description = "",
        handleClose = () =>{},
        textClose = "Fermer",
        textConfirmed = "",
        handleConfirmed = () =>{}
    }) => {

    return (
        <Modal toggle={true} handleClose={() => handleClose()}>
            <div id={"alert-modal"}>
                {title ? <div className={"alert-modal-title"}>{title}</div> : null}
                <div className={"alert-modal-description"}>{description}</div>
                <div className={"alert-modal-footer"}>
                    {textConfirmed?.length ? (
                        <Button
                            size={"small"}
                            className={"mr-8"}
                            btnStyle={"primary-reverse"}
                            onClick={() => {
                                handleConfirmed();
                                handleClose();
                            }}
                            content={textConfirmed}/>
                    ) : null}
                    <Button
                        size={"small"}
                        className={textConfirmed?.length ? "ml-8" : ""}
                        btnStyle={textConfirmed?.length ? "grey" : "primary-reverse"}
                        onClick={() => handleClose()}
                        content={textClose}/>
                </div>
            </div>
        </Modal>
    )
}
export default AlertModal;