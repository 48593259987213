import React, {Fragment} from 'react';
import { Link } from "react-router-dom";
import Icon from "./Icon";
import Badge from "./badge";
import {SpinLoader} from "./Loader";

const InsideButton = ({
                          content = '',
                          icon = '',
                          iconClassName = '',
                          iconSide = 'left',
                          badgeContent = "",
                          badgeStyle = '',
                          badgeClassName = '',
                          loading
                      }) => {
    return (
        <Fragment>
            {loading ? <SpinLoader className={"small"}/> : (
                <Fragment>
                    {iconSide === "left" && icon.length !== 0 &&
                    <Icon type={icon} className={`${content ? 'btn-icon-left' : ''} ${iconClassName}`}/>}
                    <span className={"name"}>{content}</span>
                    {iconSide === "right" && icon.length !== 0 &&
                    <Icon type={icon} className={`${content ? 'btn-icon-right' : ''} ${iconClassName}`}/>}
                    {badgeContent !== "" &&
                    <Badge btnStyle={badgeStyle} content={badgeContent} className={`btn-badge-right ${badgeClassName}`}/>}
                </Fragment>
            )}
        </Fragment>
    )
};

const BtnSize = (size) => {
    let sizeCheck = ["small", "", "large"];
    if (sizeCheck.includes(size)) {
        return (size)
    }
    return (
        ""
    )
};

const BtnStyle = (btnStyle) => {
    let styleCheck = ["", "primary", "primary-reverse", "cancel", "cancel-reverse", "forbid", "edit", "white", "grey"];
    if (styleCheck.includes(btnStyle)) {
        return (btnStyle)
    }
    return (
        ""
    )
};

const BtnCorner = (corner) => {
    let cornerCheck = ["btn-rounded-corner", "btn-rounded"];
    if (cornerCheck.includes(corner)) {
        return (corner)
    }
    return (
        ""
    )
};

const Button = ({
                    isBtn = true,
                    className = '',
                    size = 'small',
                    btnStyle = '',
                    corner = "btn-rounded-corner",
                    disabled = false,
                    active = false,
                    to = '',
                    content = '',
                    icon = '',
                    iconClassName = '',
                    iconSide = 'left',
                    badgeContent = "",
                    badgeStyle = '',
                    badgeClassName = '',
                    btnType = '',
                    loading,
                    ...props
                }) => {
    let options = `${isBtn ? 'btn' : ''} ${className} ${BtnSize(size)} ${BtnStyle(btnStyle)} ${isBtn ? BtnCorner(corner) : "cursor-pointer"}  ${active ? 'active' : ''} ${disabled ? 'unactive' : ''}`;

    if (btnType) {
        return (
            <button type={btnType} className={options} {...props}>
                <InsideButton content={content}
                              icon={icon}
                              iconSide={iconSide}
                              iconClassName={iconClassName}
                              badgeStyle={badgeStyle}
                              badgeContent={badgeContent}
                              badgeClassName={badgeClassName}
                              loading={loading}
                />
            </button>
        )
    } else if (!to) {
        return (<div className={options} {...props}>
            <InsideButton content={content}
                          icon={icon}
                          iconSide={iconSide}
                          iconClassName={iconClassName}
                          badgeStyle={badgeStyle}
                          badgeContent={badgeContent}
                          badgeClassName={badgeClassName}
                          loading={loading}
            />
        </div>)
    } else {
        return (<Link to={to} className={options} {...props}>
            <InsideButton content={content}
                          icon={icon}
                          iconSide={iconSide}
                          iconClassName={iconClassName}
                          badgeStyle={badgeStyle}
                          badgeContent={badgeContent}
                          badgeClassName={badgeClassName}
                          loading={loading}
            />
        </Link>)
    }
};


const ButtonGroup = ({className = '', ...props}) => {
    return (
        <div className={`button-group ${className}`} {...props}>
            {props.children}
        </div>
    )
};

export {Button, ButtonGroup};