import React from 'react';
import {Helmet} from "react-helmet";
import "./Maintenance.scss";
import logoSrc from '../../../images/logoc-darken.png';

const Maintenance = () => {
    const backupPath = sessionStorage.getItem('maintenance-backup-path') || '/';
    return (
        <div id="maintenance">
            <Helmet>
                Maintenance
                <meta name="prerender-status-code" content="404" />
            </Helmet>
            <div className="maintenance-wrapper">
                <img src={logoSrc} alt="logo" />
                <h2>Nous améliorons votre expérience sur Voissa</h2>
                <p>
                Nous sommes actuellement en train de réaliser des travaux de maintenance pour améliorer votre expérience sur notre site.<br/>Pendant cette période, vous pourriez rencontrer des interruptions temporaires de service ou des anomalies dans la navigation.
                </p>
                <a href={backupPath}>Recharger</a>
            </div>
        </div>
    )
};

export default Maintenance;