import React from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TextError.scss';
import classNames from 'classnames';

const TextError = ({ children, show, center, className, ...props }) => {
    return show ? (
        <div className={classNames("text-error", className, {center})} {...props}>
            <FontAwesomeIcon icon={faTriangleExclamation}/> {children}
        </div>
    ) : null;
};

export default TextError;