import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import styles from './WarningEditor.module.scss';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
    $createParagraphNode,
    $createTextNode,
    $getRoot,
    CLEAR_EDITOR_COMMAND,
    CONTROLLED_TEXT_INSERTION_COMMAND
} from "lexical";

function onError(error) {
    console.error(error);
}

function RefPlugin({ editorRef }) {
    const [editor] = useLexicalComposerContext()
    editorRef.current = editor
    return null;
}

function InsertText({text = ""}) {
    const [editor] = useLexicalComposerContext();

    useEffect(()=>{
        if (typeof text === "string" && text.length > 0){

            editor.update(() => {
                const root = $getRoot();
                const paragraph = $createParagraphNode();
                paragraph.append(
                    $createTextNode(text)
                );
                root.clear();
                paragraph.select();
                root.append(paragraph);
            });
        }
    }, [editor, text]);

    return null
}

const WarningEditor = ({onChange=(editorState)=>{}, text}, ref)=>{
    const initialConfig = {
        namespace: 'MyEditor',
        onError,
        nodes: [
            // , AutoLinkNode
        ]
    };

    const editorRef = useRef();
    useImperativeHandle(ref, () => ({
        insert: (text) => {
            const editor = editorRef.current;
            editor.focus();
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND);
            editor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, text);
        }
    }));

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className={styles["warning-editor"]} >
                <div className={styles["editor-wrapper"]}>
                    <div className={styles["editor-container"]}>
                        <InsertText text={text}/>
                        <PlainTextPlugin
                            contentEditable={<ContentEditable className={styles["editor-input"]}/>}
                            placeholder={<div className={styles["editor-placeholder"]}>Message</div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <RefPlugin editorRef={editorRef}/>
                        <OnChangePlugin onChange={onChange} ignoreSelectionChange={true}/>
                        <HistoryPlugin />
                        <ClearEditorPlugin/>
                    </div>
                </div>
            </div>
        </LexicalComposer>
    );
}

export default forwardRef(WarningEditor)