const ARTICLE = {
    SUCCESS : {
        CREATE: "Article créé !",
        UPDATE: "Article sauvegardé !",
        REPORT: "Votre signalement a bien été envoyé !",
        DELETE: "Article supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre article. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre article. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        DELETE: "Un problème est survenu lors de la suppression de votre article. Veuillez réessayer"
    }
};

const CURRENT_USER = {
    SUCCESS : {
        UPDATE: "Profil mis à jour !",
        DELETE: "Demande enregistré",
        REQUEST_DELETE : "Demande enregistré",
        REQUEST_ACCEPTED : "Demande enregistré",
        CANCEL_REQUEST_DELETE: "Demande enregistré",
        CREATE_FOLLOW: "Ce profil a bien été ajouté à vos suivis !",
        DELETE_FOLLOW: "Ce profil a bien été retiré de vos suivis !",
        CREATE_FRIEND: "Votre demande d'ami a bien été envoyé !",
        DELETE_FRIEND: "Votre demande a bien été envoyé !",
    },
    ERROR : {
        UPDATE: "Impossible de mettre votre profil à jour",
        DELETE: "Impossible d'effectuer cette action",
        REQUEST_DELETE : "Impossible d'effectuer cette action",
        REQUEST_ACCEPTED : "Impossible d'effectuer cette action",
        CANCEL_REQUEST_DELETE: "Impossible d'effectuer cette action",
        CREATE_FOLLOW: "Un problème est survenu lors de la ajout a vos suivies !",
        DELETE_FOLLOW: "Un problème est survenu lors du retrait a vos suivies !",
        CREATE_FRIEND: "Impossible d'effectuer cette action",
        DELETE_FRIEND: "Impossible d'effectuer cette action",
    }
}

const USER = {
    SUCCESS : {
        UPDATE: "Profil mis à jour !",
        DELETE: "Demande enregistré",
        REPORT: "Votre signalement a bien été envoyé !",
    },
    ERROR : {
        UPDATE: "Impossible de mettre votre profil à jour",
        DELETE: "Impossible d'effectuer cette action",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
    }
}

const FRIEND = {
    SUCCESS : {
        UPDATE_FAVORITE: "ami mis à jour !",
        DELETE: "Demande enregistré",
    },
    ERROR : {
        UPDATE_FAVORITE: "Impossible de mettre votre ami à jour",
        DELETE: "Impossible d'effectuer cette action"
    }
}

const LIKE = {
    SUCCESS : {
        CREATE: "Merci pour votre like !",
        DELETE: "Like retiré",
    },
    ERROR : {
        CREATE: "Impossible d'effectuer cette action",
        DELETE: "Impossible d'effectuer cette action"
    }
}

const MAIL = {
    SUCCESS : {
        SEND_ENROLLMENT: "Un nouvel email vient d'être envoyé",
    },
    ERROR : {
        SEND_ENROLLMENT: "impossible d'envoyer un email !",
    }
}

const FORUM_TOPIC = {
    SUCCESS : {
        CREATE: "Topic créé !",
        UPDATE: "Topic sauvegardé !",
        MOVE: "Topic déplacé !",
        REPORT: "Votre signalement a bien été envoyé !",
        CREATE_FOLLOW: "Ce topic a bien été ajouté à vos suivis !",
        DELETE_FOLLOW: "Ce topic a bien été retiré de vos suivis !",
        DELETE: "Topic supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre topic. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre topic. Veuillez réessayer",
        MOVE: "Un problème est survenu lors de la sauvegarde de votre topic. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        CREATE_FOLLOW: "Un problème est survenu lors de la ajout a vos suivies !",
        DELETE_FOLLOW: "Un problème est survenu lors du retrait a vos suivies !",
        DELETE: "Un problème est survenu lors de la suppression de votre topic. Veuillez réessayer"
    }
};

const FORUM_COMMENT = {
    SUCCESS : {
        CREATE: "Réponse créée !",
        UPDATE: "Réponse sauvegardée !",
        REPORT: "Votre signalement a bien été envoyé !",
        DELETE: "Réponse supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre réponse. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre réponse. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        DELETE: "Un problème est survenu lors de la suppression de votre réponse. Veuillez réessayer"
    }
};

const COMMENT = {
    SUCCESS : {
        CREATE: "Réponse créée !",
        UPDATE: "Réponse sauvegardée !",
        REPORT: "Votre signalement a bien été envoyé !",
        DELETE: "Réponse supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre réponse. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre réponse. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        DELETE: "Un problème est survenu lors de la suppression de votre réponse. Veuillez réessayer"
    }
};

const TAG_MODERATION = {
    SUCCESS : {
        UPDATE: "Information mis à jour !",
        REPORT: "Votre signalement a bien été envoyé !",
    },
    ERROR : {
        UPDATE: "Impossible de mettre à jour",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
    }
}

const MEDIA = {
    SUCCESS : {
        CREATE: "Média créé !",
        UPDATE: "Média sauvegardé !",
        UPDATE_SFW: "Média sauvegardé !",
        REPORT: "Votre signalement a bien été envoyé !",
        MOVE: "Média déplacé !",
        DELETE: "Média supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre média. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre média. Veuillez réessayer",
        UPDATE_SFW: "Un problème est survenu lors de la sauvegarde. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        MOVE: "Un problème est survenu lors de la sauvegarde de votre média. Veuillez réessayer",
        DELETE: "Un problème est survenu lors de la suppression de votre média. Veuillez réessayer"
    }
};

const ALBUM = {
    SUCCESS : {
        CREATE: "Collection créé !",
        UPDATE: "Collection sauvegardé !",
        REPORT: "Votre signalement a bien été envoyé !",
        MOVE: "Collection déplacé !",
        DELETE: "Collection supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre collection. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre collection. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        MOVE: "Un problème est survenu lors de la sauvegarde de votre collection. Veuillez réessayer",
        DELETE: "Un problème est survenu lors de la suppression de votre collection. Veuillez réessayer"
    }
};

const STATUS = {
    SUCCESS : {
        CREATE: "Statut créée !",
        UPDATE: "Statut sauvegardée !",
        REPORT: "Votre signalement a bien été envoyé !",
        DELETE: "Statut supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre statut. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre statut. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        DELETE: "Un problème est survenu lors de la suppression de votre statut. Veuillez réessayer"
    }
};

const WARNING = {
    SUCCESS : {
        CREATE: "Note de moderation créée !",
        UPDATE: "Note de moderation sauvegardée !",
        DELETE: "Note de moderation supprimée !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre note de moderation. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre note de moderation. Veuillez réessayer",
        DELETE: "Un problème est survenu lors de la suppression de votre note de moderation. Veuillez réessayer"
    }
};

const STORY = {
    SUCCESS : {
        CREATE: "Histoire créé !",
        UPDATE: "Histoire sauvegardé !",
        REPORT: "Votre signalement a bien été envoyé !",
        DELETE: "Histoire supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre histoire. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre histoire. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        DELETE: "Un problème est survenu lors de la suppression de votre histoire. Veuillez réessayer"
    }
};

const ROOM = {
    SUCCESS : {
        CREATE: "Conversation créé !",
        UPDATE: "Conversation sauvegardé !",
        REPORT: "Votre signalement a bien été envoyé !",
        DELETE: "Conversation supprimé !"
    },
    ERROR : {
        CREATE: "Un problème est survenu lors de la création de votre conversation. Veuillez réessayer",
        UPDATE: "Un problème est survenu lors de la sauvegarde de votre conversation. Veuillez réessayer",
        REPORT: "Un problème est survenu lors de l'envoi de votre signalement",
        DELETE: "Un problème est survenu lors de la suppression de votre conversation. Veuillez réessayer"
    }
};

export {
    ARTICLE,
    CURRENT_USER,
    FORUM_TOPIC,
    FORUM_COMMENT,
    COMMENT,
    USER,
    TAG_MODERATION,
    MEDIA,
    ALBUM,
    STATUS,
    FRIEND,
    MAIL,
    WARNING,
    LIKE,
    STORY,
    ROOM
};