import React, {useState} from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import _ from "lodash";
import {Can} from "../../../ability-context";
import {Button} from "../Button";
import {PageLoader} from "../Loader";
import createLink from "../../../libs/createLink";
import {config} from "../../../config";
import {isNoFollowProfil} from "../../../libs/helper";
import WarningEditor from "./WarningEditor/WarningEditor";
import convertToNext from "../../../libs/lexical/convertissors/simple";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {USER_CURRENT_KEY} from "../../../api/public-voissa/users";
import {fetchWarning, WARNING_KEY} from "../../../api/public-voissa/warnings";


const WarningDetail = ({
        warningId = null,
        handleClose = () =>{},
        handleAccept = () =>{},
        handleDeleteWarn = () =>{},
        updateStatus = (warningId, data) => {},
        updateContent = (warningId, data) =>{}
    }) =>{

    const queryClient = useQueryClient();

    const dataCurrentUser = queryClient.getQueryData([USER_CURRENT_KEY]);

    const [memberNote, setMemberNote] = useState({});
    const [moderatorNote, setModeratorNote] = useState({});

    const {isSuccess : isSuccessWarning, data: dataWarning} = useQuery(
        [WARNING_KEY, warningId],
        () => fetchWarning(warningId),
        {
            staleTime: (1000 * 60 * 2),
            enabled: !!warningId
        }
    );

    function handleSubmit() {
        let {text : note} = convertToNext(memberNote);
        let {text : moderator} = convertToNext(moderatorNote);
        updateContent(warningId, {
            note,
            moderatorNote : moderator
        });
        handleClose();
    }

    let createdDate = moment();
    let endDate = moment();
    let relLinkProfile = {}
    let isCertified = false;

    if(isSuccessWarning){
        createdDate = moment(dataWarning.createdAt);
        endDate = moment(_.get(dataWarning,"restriction.endedAt") || null);
        relLinkProfile = isNoFollowProfil(dataWarning.moderator) ? { rel: "nofollow" } : {};
        isCertified = (_.get(dataWarning, "owner._id") && _.get(dataCurrentUser, "_id") && dataCurrentUser._id.toString() === dataWarning.owner._id.toString() && !_.get(dataWarning, "owner.profile.photoCertifiedId")) || false
    }

    return !isSuccessWarning ? <PageLoader/> : (
        <div id="warningDetail" className="modal-container">
            <div className="modal-topline">Message de modération</div>
            <div className={"modal-body"}>
                <div className="warning-detail-topline">
                    <div className={"column text medium black"}>
                        {dataWarning.moderator && <span>Message de modération par <Link
                            to={createLink("userProfile", dataWarning.moderator)} {...relLinkProfile}>{dataWarning.moderator.username}</Link></span>}
                        <span>Raison: {dataWarning.tag && (dataWarning.tag.title || "Autre")} </span>
                        <span>Restriction: {config.warningRestriction[_.get(dataWarning,'restriction.type') || 0]}</span>
                        {dataWarning.restriction.type === 1 && <span>Durée: {dataWarning.restriction.isPermanent ? "Permanente" :
                            dataWarning.restriction.startedAt ?
                                `du ${moment(dataWarning.restriction.startedAt).format("DD/MM/YYYY")} au ${moment(dataWarning.restriction.endedAt).format("DD/MM/YYYY")}`
                                :
                                (
                                    `${endDate.diff(createdDate, 'hours') + 1} h`
                                )
                        }
                            </span>}
                        <span>
                                {!dataWarning.isAccepted ? (
                                    <div  className={"btn primary mr-8 btn-accepted"} onClick={()=> handleAccept(warningId)}>Accepter</div>
                                ) : null}

                            <Can action={"delete"} subject={dataWarning} modelName="Warning">
                                    <div className={"btn cancel btn-deleted mr-8"} onClick={()=> handleDeleteWarn(warningId)} >
                                        Supprimer&nbsp;
                                    </div>
                                </Can>
                                <Can action={"update-status"} subject={dataWarning} modelName="Warning">
                                    <div className={"btn btn-status "}
                                         onClick={()=> updateStatus(warningId, {status : !dataWarning.status})}
                                    >
                                        {dataWarning.status ? "Désactiver" : "Activer"}
                                    </div>
                                </Can>
                            </span>

                    </div>
                </div>
                <div className="column my-16">
                    <div className="text label bold pb-4">Note au membre</div>
                    <div className="text medium black pre-line">
                        <Can action="update-status" subject="Warning" passThrough>
                            {can => {
                                return can ? (
                                    <WarningEditor onChange={(editorState)=>setMemberNote(editorState)}  text={dataWarning.note}/>
                                ) : (
                                    dataWarning.note
                                )
                            }}
                        </Can>

                    </div>
                </div>
                <Can action="update-status" subject="Warning">
                    <div className="column my-16">
                        <div className="text label bold pb-4">Note pour les modérateurs</div>
                        <div className="text medium black pre-line">
                            <WarningEditor onChange={(editorState)=>setModeratorNote(editorState)}  text={dataWarning.moderatorNote}/>
                        </div>
                    </div>
                </Can>

            </div>
            <div className="modal-baseline">
                <div className={"link-group"}>
                    <a  className={"btn primary mr-8"} href={createLink("charter")} target={"_blank"} rel="noopener noreferrer">
                        Charte
                    </a>

                    <a  className={"btn primary mr-8"} href={"mailto:Assistance@voissa.com"}>
                        Assistance@voissa.com
                    </a>

                    {isCertified ? <a className={"btn primary"} href={createLink("userMeSettingsAbonnement", dataCurrentUser)} target={"_blank"} rel="noopener noreferrer">
                        Certifier mon compte
                    </a> : null}
                </div>
                <Can action="update" subject="Warning">
                    <Button
                        className="btn-close"
                        size={"small"}
                        btnStyle={"primary"}
                        onClick={() => handleSubmit()}
                        content={"Valider"}
                    />
                </Can>
                <Button
                    className="btn-close"
                    size={"small"}
                    btnStyle={"primary-reverse"}
                    onClick={() => handleClose()}
                    content={"Fermer"}
                />
            </div>
        </div>
    )
};

export default WarningDetail;