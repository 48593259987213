export default function userToken() {

    function getToken () {
        return localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    }

    function setToken(newToken) {
        if(newToken === null) localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        else localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, newToken);

    }


    return [getToken(), setToken];
}
