if(!window.backbuttonInitialized) {
    window.backbuttonInitialized = {
        popover : false,
        popunder : false,
        popdisclaimer : false
    }
}
const backButton = (eventType = "", params = {}) => {
    if(window.backbuttonInitialized[eventType]) return;
    window.backbuttonInitialized[eventType] = true;
    let isMobile = window.matchMedia("(min-width: 720px)").matches;
    let isChrome = RegExp(/Chrome\/[0-9.]+ (?:Mobile )?Safari\/[0-9.]+$|CriOS|Googlebot/).test(window.navigator.userAgent);
    if(params.source === "voissa"){
        params.source = "";
    }
    if(eventType === "popover" && !isChrome){
        popOver(isMobile, params);
    }
    else if(eventType === "popunder" && !isChrome){
        popUnder(isMobile, params);
    }
    else if(eventType === "popdisclaimer" && !isChrome){
        popDisclaimer(isMobile, params);
    }
    else if(eventType === "popundercallback" && !isChrome){
        popUnderCallBack(isMobile, params);
    }
}

const popDisclaimer = (isMobile = false, params) =>{
    let uriPub = isMobile ? "https://tools.emstat.fr/voissa/backbutton-mobile.php" : "https://tools.emstat.fr/voissa/backbutton-desktop.php";
    if(params.source !== ""){
        uriPub = `${uriPub}?adparameter2=${params.source}`;
    }
    let currentPath = window.location.pathname + window.location.search + window.location.hash;
    let newHash = window.location.hash;
    if ( newHash === '' ) {
        newHash = '#disc=1';
    }
    else {
        newHash += '&disc=1';
    }
    let newUri = window.location.pathname + window.location.search + newHash;

    window.history.replaceState({}, document.title, newUri);
    window.history.pushState({}, document.title, currentPath);
    customAddEventListener(currentPath, uriPub);

}

const popOver = (isMobile = false, params) =>{
    let uriPub = isMobile ? "https://tools.emstat.fr/voissa/backbutton-mobile.php" : "https://tools.emstat.fr/voissa/backbutton-desktop.php";
    if(params.source !== ""){
        uriPub = `${uriPub}?adparameter2=${params.source}`;
    }
    let currentPath = window.location.pathname + window.location.search + window.location.hash;
    let newHash = window.location.hash;
    if ( newHash === '' ) {
        newHash = '#over=1';
    }
    else {
        newHash += '&over=1';
    }
    let newUri = window.location.pathname + window.location.search + newHash;

    window.history.replaceState({}, document.title, newUri);
    window.history.pushState({}, document.title, currentPath);
    customAddEventListener(currentPath, uriPub);

}

const popUnder = (isMbile = false, params) =>{
    let newHash = window.location.hash;
    if ( newHash === '' ) {
        newHash = '#hist=1';
    }
    else {
        newHash += '&hist=1';
    }
    let newUri = window.location.pathname + window.location.search + newHash;
    window.history.replaceState({}, document.title, newUri);
}

const popUnderCallBack = (isMobile = false, params) =>{
    let uriPub = isMobile ? "https://tools.emstat.fr/voissa/pop-backbutton-mobile.php" : "https://tools.emstat.fr/voissa/pop-backbutton-desktop.php";
    if(params.source !== ""){
        uriPub = `${uriPub}?adparameter2=${params.source}`;
    }
    let newHash = window.location.hash.replace(/(.*)[#&]hist=1(.*)/, '$1$2');
    window.history.replaceState(null, document.title, window.location.pathname + window.location.search + newHash);
    window.location = uriPub;
}

function customAddEventListener (currentPath = "", pubUrl = "") {
    window.addEventListener("popstate", function () {
        //setBackButtonUsed(true);
        let isValidedHash = window.location.hash.match(/[#&](hist|over|disc)=1/);
        window.history.replaceState(null, document.title, currentPath);
        if(isValidedHash){
            setTimeout(function () {
                window.top.location.href = pubUrl;
            }, 1);
        }
    }, false);
}


export default backButton;