import React, {createElement, Fragment, PureComponent} from 'react';

const noop = () => {
};
const renderChildren = Fragment
    ? children => createElement.apply(null, [Fragment, null].concat(children))
    : React.Children.only;

export default class Can extends PureComponent {

    constructor(...args) {
        super(...args);
        this.unsubscribeFromAbility = noop;
        this._isAllowed = false;
        this._ability = null;
    }

    get allowed() {
        return this._isAllowed;
    }

    componentWillUnmount() {
        this.unsubscribeFromAbility();
    }

    connectToAbility(ability) {
        if (ability === this._ability) {
            return;
        }

        this.unsubscribeFromAbility();
        this._ability = null;

        if (ability) {
            this._ability = ability;
            this.unsubscribeFromAbility = ability.on('updated', () => this.forceUpdate());
        }
    }

    isAllowed() {
        const params = this.props;
        const action = params.action;
        const subject = (params.modelName && typeof params.subject !== 'string') ? {constructor: {name: params.modelName}, ...params.subject} : params.subject;
        const field = params.field;
        const can = params.not ? 'cannot' : 'can';
        if (Array.isArray(action)) {
            return action.reduceRight((acc, currentAction) => (params.ability[can](currentAction, subject, field) || !!acc), false);
        } else return params.ability[can](action, subject, field);
    }

    render() {
        this.connectToAbility(this.props.ability);
        this._isAllowed = this.isAllowed();
        return this.props.passThrough || this._isAllowed ? this.renderChildren() : null;
    }

    renderChildren() {
        const {children, ability} = this.props;
        const elements = typeof children === 'function'
            ? children(this._isAllowed, ability)
            : children;

        return renderChildren(elements);
    }
}