import React from "react";
import ReactDOM from "react-dom";
import Popup from "./Popup";
import * as noScroll from "no-scroll";

class Modal extends React.Component {
    constructor(props){
        super(props);

        if (document.getElementById("modal").childElementCount >= 1) {
            noScroll.on();
        }
    }
    componentDidUpdate() {
        if (document.getElementById("modal").childElementCount >= 1) {
            noScroll.on();
        }
        if (document.getElementById("modal").childElementCount < 1) {
            noScroll.off();
        }
    }

    componentWillUnmount() {
        if (document.getElementById("modal").childElementCount < 1) {
            noScroll.off();
        }
    };

    render() {
        return ReactDOM
            .createPortal(
                <Popup {...this.props} >
                    {this.props.children}
                </Popup>,
                document.querySelector("#modal")
            );
    }
}

export default Modal;