import React from "react";
import ReactDOM from "react-dom";
import * as noScroll from "no-scroll";
import Icon from "../../components/Icon";

import "./EncourageSignup.scss";
import createLink from "../../../libs/createLink";
import { Link } from "react-router-dom";
import argument1 from "./images/argument-1.png";
import argument2 from "./images/argument-2.png";
import argument3 from "./images/argument-3.png";

export const EncourageSignup = ({
            handleClose = () =>{}
      }) => {


    return (
        <div id={"encourage-signup"}>
            <div className="full-bg">

            </div>
            <div className="center">
                <div className="container-signup">
                    <div className="header">
                        <div className="header-text">
                            <div className="logo"></div>
                            <div className="subtitle">Le plus bel espace de liberté</div>
                        </div>
                        <div className="close-mobile">
                            <Icon type={"close-x"} callback={()=>handleClose()} />
                        </div>
                    </div>
                    <div className="window-wrapper">
                        <div className="window">
                            <div className="close-desktop">
                                <Icon type={"close-x"} callback={()=>handleClose()} />
                            </div>
                            <div className="illustration"></div>
                            <div className="main-section">
                                <div className="form">
                                    <h2>Inscris-toi pour</h2>
                                    <ul className="arguments">
                                        <li>
                                            <div className="icon">
                                                <img alt={""} src={argument1} />
                                            </div>
                                            <label>Te montrer et sentir le frisson</label>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img alt={""} src={argument2} />
                                            </div>
                                            <label>Chatter, échanger, commenter, liker</label>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <img alt={""} src={argument3} />
                                            </div>
                                            <label>Ne plus être embêté avec cette pop</label>
                                        </li>
                                    </ul>
                                    <div className="cta-list">
                                        <Link className="signup-cta" to={createLink("signup")} onClick={handleClose}>
                                                <div className="line1">Inscription</div>
                                                <div className="line2">(c'est gratuit)</div>
                                        </Link>
                                        <Link className={"signin-cta"} to={createLink("signin")} onClick={handleClose}>Connexion</Link>
                                    </div>
                                </div>
                                <div className="text">
                                    Voissa est une communauté bienveillante, un lieu de partage sans tabou, convivial,
                                    dans lequel les membres peuvent se montrer en toute sécurité. Seul(e) ou en couple,
                                    à l’aise ou pas encore tout à fait… <br/>
                                    Nous t’attendons parmi nous, qui que tu sois !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class ModalEncourageSignup extends React.Component {
    constructor(props){
        super(props);

        if (document.getElementById("modal").childElementCount >= 1) {
            noScroll.on();
        }
    }
    componentDidUpdate() {
        if (document.getElementById("modal").childElementCount >= 1) {
            noScroll.on();
        }
        if (document.getElementById("modal").childElementCount < 1) {
            noScroll.off();
        }
    }

    componentWillUnmount() {
        if (document.getElementById("modal").childElementCount < 1) {
            noScroll.off();
        }
    };

    render() {
        return ReactDOM
            .createPortal(
                this.props.toggle ? <EncourageSignup {...this.props} /> : null,
                document.querySelector("#modal")
            );
    }
}

export default ModalEncourageSignup;