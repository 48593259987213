import React, {useEffect, useState} from 'react';
import moment from "moment";
import Icon from "./Icon";
import DatePicker from "react-datepicker";
import { registerLocale} from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import {Button} from "./Button";
import Modal from "./Modal";
import "react-datepicker/dist/react-datepicker.css";
import {LoadingBar} from "./Loader";
import * as noScroll from "no-scroll";
import Drawer from "../components2/Drawer/Drawer";

const TextInputField = ({error, className = "", ...props}) => (
    <div className={`form-field custom-text-input ${className}`}>
        <input type="text" {...props} value={props.value || ''} className={error ? 'error' : ''}/>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

const TextInputFieldUrl = ({error, className = "", ...props}) => (
    <div className={`form-field custom-text-input ${className}`}>
        <input type="url" {...props} value={props.value || ''} className={error ? 'error' : ''}/>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

class SearchTextInputField extends  React.Component{
    constructor(props){
        super(props);
        this.state = {
            showSubSearchBar: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleOpenSubSearch = this.handleOpenSubSearch.bind(this);
        this.handleCloseSubSearch = this.handleCloseSubSearch.bind(this);

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleCloseSubSearch);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleCloseSubSearch);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenSubSearch(){
        if(!this.state.showSubSearchBar){
            this.setState({showSubSearchBar: true})
        }
    }

    handleCloseSubSearch(event){
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({showSubSearchBar: false});
        }
    }

    handleItemClick(listItem){
        this.setState({showSubSearchBar: false});
        this.props.getItem(listItem);
    }

    render(){
        return(
            <div className={`input-search-bar ${this.state.showSubSearchBar ? "active" : ""}`} ref={this.setWrapperRef}>
                <div className={`form-field custom-text-input`}>
                    <input type="text"
                           onFocus={()=>this.handleOpenSubSearch()}
                           placeholder={this.props.placeholder}
                           className={this.props.error ? 'error' : ''}
                           onChange={(event) => this.props.handleSearch(event.target.value)}
                           value={this.props.value}/>
                    <Icon type={"chevron-down"} />
                </div>
                {this.state.showSubSearchBar && (
                    <ul className={`sub-search-bar`}>
                        <div className={this.props.loading ? "show-loading-bar" : "hide-loading-bar"}>
                            <LoadingBar />
                        </div>
                        {this.props.list.map(item => (
                            <li className={"sub-search-bar__item"}
                                key={item._id}
                                onClick={()=>this.handleItemClick(item)}
                            >
                                {item.title || item.username}
                            </li>
                        ))}
                        {this.props.total === 0 &&(
                            <li className={"sub-search-bar__item no-result"}>Aucun résultat trouvé</li>
                        )}
                    </ul>
                )}
            </div>
        )
    }
}

class PasswordInputField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
        };
    }

    render() {
        let data = this.props;
        if(!data.error){
            data = {
                ...data,
                error : ""
            };
        }
        //TODO : ALEX Revoir le probleme this.props.error {...this.props}
        return (
            <div className={`form-field custom-password-input ${this.props.className}`}>
                <div className={"password-input-wrapper"}>
                    <input type={this.state.showPassword ? 'text' : 'password'} className={this.props.error ? 'error' : ''} {...data}
                           value={this.props.value || ''} />
                    <span className={"show-password"}
                          onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                        <Icon type={this.state.showPassword ? "eye-cut" : "eye"}/>
                    </span>
                </div>
                {this.props.error && this.props.error.length >= 1 && (<div className="error-message">{this.props.error}</div>)}
            </div>
        )
    }
}

const EmailInputField = ({error, className = "", ...props}) => (
    <div className={`form-field custom-email-input ${className}`}>
        <input type="email" {...props} value={props.value || ''} className={error ? 'error' : ''}/>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

class DateInputField extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.handleValue = this.handleValue.bind(this);
        registerLocale('fr', fr);
    }

    toggleCalendar () {
        this.setState({isOpen: !this.state.isOpen});
    }

    handleValue(value){
        this.props.handleValue(this.props.nameOfValueToUpdate, moment(value).format(this.props.convertToFormat ? this.props.convertToFormat : "YYYY-MM-DD"));
    }

    render() {
        let format = this.props.format ? this.props.format : "DD/MM/YYYY";
        return(
            <div className={`form-field custom-date-input ${this.props.className}`}>
                <Button btnStyle="grey-outline"
                        size={"small"}
                        className={this.props.error ? 'error' : ''}
                        onClick={()=>this.toggleCalendar()}
                        content={moment(this.props.value || Date.now()).format(format)} />
                {
                    this.state.isOpen && (
                        <Drawer
                            xsmall
                            open={this.state.isOpen}
                            handleClose={()=>this.setState({isOpen: false})}
                        >
                            <div className={"modal-container date-popup"}>
                                {this.props.showTime ? (
                                    <DatePicker
                                        selected={moment(this.props.value || moment(), moment.defaultFormat).toDate()}
                                        onChange={this.handleValue}
                                        minDate={moment().toDate()}
                                        dateFormat={format}
                                        timeInputLabel="Heure :"
                                        showTimeInput
                                        inline
                                        locale={"fr"}
                                        withPortal/>
                                ) : (
                                    <DatePicker
                                        selected={moment(this.props.value || moment(), moment.defaultFormat).toDate()}
                                        onChange={this.handleValue}
                                        dateFormat={format}
                                        minDate={moment().toDate()}
                                        inline
                                        locale={"fr"}
                                        withPortal/>
                                )}
                                <div className={"modal-baseline"}>
                                    <Button size={"small"}
                                            btnStyle={"primary-reverse"}
                                            onClick={()=>this.setState({isOpen: false})}
                                            content={"Valider"} />
                                </div>
                            </div>
                        </Drawer>
                    )
                }
                {this.props.error && this.props.error.length >= 1 && (<div className="error-message">{this.props.error}</div>)}
            </div>
        )
    }
}

const DateInputFieldBirthdaySelect = ({
        birthday = null,
        updateBirtday = () =>{},
        ageMin = 18,
        ageMax = 120
    }) => {
    const currentYear = new Date().getFullYear();

    const [birthdays, setBirthdays] = useState({
        day : "01",
        month : "01",
        year : currentYear - 18
    })

    function listNumbers(start = 0, end = 0) {
        let i;
        let list = [];
        for (i = start; i <= end; i++) {
            list.push({
                value: i < 10 ? `0${i}` : i,
                label: i < 10 ? `0${i}` : i
            });
        }
        return list;
    }

    function listNumbersReverse(start = 0, end = 0) {
        let i;
        let list = [];
        for (i = start; i >= end; --i) {
            list.push({
                value: i,
                label: i
            });
        }
        return list;
    }

    useEffect(() =>{
        if(birthday){
            setBirthdays({
                day : moment(birthday).format('DD'),
                month : moment(birthday).format('MM'),
                year : moment(birthday).format('YYYY')
            })
        }
    }, [birthday]);

    return (
        <div className={"form-field input-birthday"}>
            <div className={"date-select"}>
                <select
                    onChange={(event) => updateBirtday(moment(`${birthdays.year}-${birthdays.month}-${event.target.value}`))}
                    value={birthdays.day !== undefined ? birthdays.day : 0}>
                    <option disabled value={0}>jour</option>
                    {listNumbers(1, birthdays.month === "02" ? moment().endOf('month').format("DD") : 31).map((item, index) => (
                        <option value={item.value} key={index}>{item.label}</option>
                    ))}
                </select>
            </div>
            <div className={"date-select"}>
                <select
                    onChange={(event) => updateBirtday(moment(`${birthdays.year}-${event.target.value}-${birthdays.day}`))}
                    value={birthdays.month !== undefined ? birthdays.month : 0}>
                    <option disabled value={0}>mois</option>
                    {listNumbers(1, 12).map((item, index) => (
                        <option value={item.value} key={index}>{item.label}</option>
                    ))}
                </select>

            </div>
            <div className={"date-select"}>
                <select
                    onChange={(event) => updateBirtday(moment(`${event.target.value}-${birthdays.month}-${birthdays.day}`))}
                    value={birthdays.year !== undefined ? birthdays.year : 0}>
                    <option disabled value={0}>année</option>
                    {listNumbersReverse((currentYear - ageMin), (currentYear - ageMax)).map((item, index) => (
                        <option value={item.value} key={index}>{item.label}</option>
                    ))}
                </select>

            </div>
        </div>
    )
};

class DateInputFieldBirthday extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.handleValue = this.handleValue.bind(this);
        this.closeModal = this.closeModal.bind(this);
        registerLocale('fr', fr);
    }

    toggleCalendar () {
        this.setState({isOpen: !this.state.isOpen}, ()=>{noScroll.on();});
    }

    closeModal(){
        this.setState({isOpen: false}, ()=>{noScroll.off();});
    }

    handleValue(value){
        this.props.handleValue(this.props.nameOfValueToUpdate, moment(value).format(this.props.convertToFormat ? this.props.convertToFormat : "YYYY-MM-DD"));
    }

    render() {
        let format = this.props.format ? this.props.format : "DD/MM/YYYY";
        return(
            <div className={`form-field custom-date-input ${this.props.className ? this.props.className : ""}`}>
                <Button btnStyle="grey-outline"
                        size={"small"}
                        className={this.props.error ? 'error' : ''}
                        onClick={()=>this.toggleCalendar()}
                        content={this.props.value ? moment.utc(this.props.value || Date.now()).format(format) : 'JJ/MM/AAAA'} />
                {
                    this.state.isOpen ? (
                        <Modal toggle={this.state.isOpen} handleClose={()=>this.closeModal()}>
                            <div className={"modal-container date-popup"}>
                                <DatePicker
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    selected={this.props.value ? moment.utc(this.props.value || moment.utc(), moment.defaultFormat).toDate() : this.props.maxDate}
                                    onChange={this.handleValue}
                                    dateFormat={format}
                                    minDate={this.props.minDate}
                                    maxDate={this.props.maxDate}
                                    locale={"fr"}
                                    inline
                                    withPortal/>
                                <div className={"modal-baseline"}>
                                    <Button size={"small"}
                                            btnStyle={"primary-reverse"}
                                            onClick={()=>this.closeModal()}
                                            content={"Valider"} />
                                </div>
                            </div>
                        </Modal>
                    ) : null
                }
                {this.props.error && this.props.error.length >= 1 && (<div className="error-message">{this.props.error}</div>)}
            </div>
        )
    }
}

const NumberInputField = ({error, className = "", ...props}) => (
    <div className={`form-field custom-number-input ${className}`}>
        <input type="number" {...props} value={props.value || ''}
               className={error ? 'error' : ''}/>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

const CheckboxInputField = ({error, label = "", htmlFor = "",classNameLabel = "", className = "", ...props}) => (
    <div className={`form-field custom-checkbox-input ${className}`}>
        <div className={"row"}>
            <input type="checkbox" {...props} value={props.value || ''} className={error ? 'error' : ''}/>
            <label htmlFor={htmlFor} className={classNameLabel}>{label}</label>
        </div>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

const RadioInputField = ({error, label = "", htmlFor = "", className = "", ...props}) => (
    <div className={`form-field custom-radio-input ${className}`}>
        <div className={"row"}>
            <input type="radio" {...props} value={props.value || ''} className={error ? 'error' : ''}/>
            <label htmlFor={htmlFor}> {label}</label>
        </div>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

const TextAreaField = ({error, className = "", ...props}) => (
    <div className={`form-field custom-textarea ${className}`}>
        <textarea {...props} className={error ? 'error' : ''}/>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

const SelectField = ({children, error, className = "", ...props}) => {
    return (
        <div className={`form-field custom-select ${className}`}>
            <select {...props} className={error ? 'error' : ''}>
                {children}
            </select>
            {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
        </div>
    )
};

const AddInputField = ({error, className = "", callback, ...props}) => (
    <div className={`form-field custom-add-input ${className}`}>
        <div className={"row"}>
            <input type="text" {...props} value={props.value || ''} className={error ? 'error' : ''}/>
            <span className={error ? 'error' : ''} onClick={()=>callback()}><Icon type="plus-circle" /></span>
        </div>
        {error && error.length >= 1 && (<div className="error-message">{error}</div>)}
    </div>
);

export {
    TextInputField,
    SearchTextInputField,
    PasswordInputField,
    EmailInputField,
    DateInputField,
    DateInputFieldBirthday,
    NumberInputField,
    CheckboxInputField,
    RadioInputField,
    TextAreaField,
    SelectField,
    AddInputField,
    DateInputFieldBirthdaySelect,
    TextInputFieldUrl
};