import React, { createContext, useContext, useState, useEffect } from 'react';

const FiltersContext = createContext();

const getStoredFilters = () => {
  const storedFilters = localStorage.getItem('filters');
  return storedFilters ? JSON.parse(storedFilters) : {};
};

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState(getStoredFilters);

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  const setFilter = (label, value) => {
    setFilters(prevFilters => {
      return { ...prevFilters, [label]: value };
    });
  };

  const getFilter = (label, defaultValue) => {
    return filters[label] === undefined ? defaultValue : filters[label];
  }

  return (
    <FiltersContext.Provider value={{ filters, setFilter, getFilter, setFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersStorage = (defaultFilters = {}) => {
  const { filters, getFilter, setFilter, setFilters } = useContext(FiltersContext);
  const filtersWithDefault = {...defaultFilters,...filters};

  return { filters : filtersWithDefault, getFilter, setFilter, setFilters };
};