import { useEffect,useState } from 'react';

const useDisableBodyScroll = () => {
    const [alreadyActive, setAlreadyActive] = useState(false);
    useEffect(() => {
        if(document.body.style.overflow === 'hidden') setAlreadyActive(true);
        document.body.style.overflow = 'hidden';
        return () => {
            if(!alreadyActive) document.body.style.overflow = 'auto';
        };
    }, [alreadyActive, setAlreadyActive]);
};

export const DisableBodyScroll = ()=>{
    useDisableBodyScroll();
    return null;
}

export default useDisableBodyScroll;