import qs from "qs";
import {apiVoissa} from "../../libs/api";


export const fetchWarning = (warningId = null, query = {}, config = {}) => apiVoissa.get(`/warnings/${warningId}?${qs.stringify(query)}`, config);
export const updateWarningStatus = ( warningId = null, data = {}, config = {}) => apiVoissa.patch(`/warnings/${warningId}/update-status`, data, config);
export const updateWarningContent = ( warningId = null, data = {}, config) => apiVoissa.patch(`/warnings/${warningId}/update-content`, data, config);

export const fetchMessagesType = (config = {}) => apiVoissa.get(`/warnings/list-type-messages`, config);

export const WARNING_KEY = `warning`;
export const WARNING_MESSAGES_TYPE_KEY = `warning-messages-type`;