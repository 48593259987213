import React from 'react';

const Badge = ({btnStyle = '', content = '', className = '', ...props}) => {
    return (
        <span className={`badge ${btnStyle} ${className}`} {...props}>
            {content}
        </span>
    )
};

export default Badge;