import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import {Helmet} from "react-helmet";
import {config} from "../../../config";
import {canonical} from "../../../libs/helper";
import createLink from "../../../libs/createLink";
import {useMutation} from "@tanstack/react-query";
import {signupUser} from "../../../api/public-voissa/users";
import Container from '../UI/Container/Container';
import Input, { SelectInput } from '../UI/Input/Input';
import Button, { BUTTON_VARIANTS } from '../UI/Button/Button';
import './Signup.scss';
import Drawer from '../Drawer/Drawer';
import { checkPasswordStrength } from '../UI/Input/PasswordStrength';
import TextError from '../UI/TextError/TextError';
import { ACTION_LOCKED } from '../../../hooks/useCurrentUserActionLocked';

const SIGNUP_SUBTITLE_LOCKED_MAP = {
    DEFAULT : ()=>"Rejoignez la communauté Voissa, c'est gratuit et en une seule étape.",
    SEARCH_MEMBERS : ()=>"Rejoignez-nous pour rechercher des membres.",
    [ACTION_LOCKED.CREATE_LIKE] : (lockedContentOwner) => <>Rejoignez-nous pour aimer la publication de <b>{lockedContentOwner?.username}</b></>,
    [ACTION_LOCKED.CREATE_FOLLOW] : (lockedContentOwner) => <>Rejoignez-nous pour suivre le profil de <b>{lockedContentOwner?.username}</b></>,
    [ACTION_LOCKED.CREATE_FRIEND] : (lockedContentOwner) => <>Rejoignez-nous pour ajouter <b>{lockedContentOwner?.username}</b> à votre cercle</>,
    [ACTION_LOCKED.MESSENGER] : (lockedContentOwner) => <>Rejoignez-nous pour discuter avec <b>{lockedContentOwner?.username}</b></>,
    VIEW_PUBLICATION: (lockedContentOwner)=><>Rejoignez-nous pour voir la publication de <b>{lockedContentOwner?.username}</b></>
}


const validateForm = (user, setError) => {
    let isValid = true;
    let errors = {};

    // Validation du username
    if (!user.username || user.username.trim().length < 2) {
        errors.username = "Doit contenir au moins 2 caractères.";
        isValid = false;
    }

    // Validation de l'email
    if (!/\S+@\S+\.\S+/.test(user.email)) {
        errors.email = "Email invalide.";
        isValid = false;
    }

    // Validation de la force du mot de passe
    if (!checkPasswordStrength(user.password).isValid) {
        errors.passwordStrengthError = "Le mot de passe est faible.";
        isValid = false;
    }

    // Sélection du genre
    if (user.gender === 0) {
        errors.gender = "Veuillez sélectionner votre genre.";
        isValid = false;
    }

    // Vérification de la date de naissance
    if (user.birthday1 === null) {
        errors.birthday1 = "Champ obligatoire.";
        isValid = false;
    }

    // Pour les couples, vérification de la deuxième date de naissance
    if ([3,4,5].includes(user.gender) && user.birthday2 === null) {
        errors.birthday2 = "Champ obligatoire.";
        isValid = false;
    }

    // Acceptation des CGU
    if (!user.cgu) {
        errors.cgu = "Vous devez accepter les Conditions Générales d'Utilisation pour continuer.";
        isValid = false;
    }

    setError(errors); // Mise à jour de l'état d'erreur avec les nouveaux messages
    return isValid;
};

const SignupFormulaire = ({
        setStep = () =>{},
        context = {}
    }) => {
    const [user, setUser] = useState({
        username: "",
        email: "",
        password: "",
        passwordConfirmed: "",
        gender: 0,
        birthday1: null,
        birthday2: null,
        source : localStorage.getItem(`${process.env.REACT_APP_TOKEN_NAME}_source`) || "voissa",
        cgu : false
    });

    useEffect(() => {
        if(window.plausible) window.plausible("SIGNUP_FORM");
        return () => {
        }
    }, []);

    const [error, setError] = useState({passwordStrengthError : false});
    
    const today = moment();
    const minDate = today.subtract(100, 'years').format('YYYY-MM-DD'); // 100 ans auparavant
    const maxDate = moment().subtract(18, 'years').format('YYYY-MM-DD'); // 18 ans auparavant


    const isGenderCouple = [3,4,5].includes(user.gender);
    //const genders = config.genderList.map((item, index) => { item = index === 0 ? "Selectionner un genre" : item; return item});
    const genders = ["Selectionner un genre", "Homme", "Femme", "Couple"]

    const {isLoading : isLoadingSignup, error : errorSignup, mutate : mutateSignup } = useMutation(data => signupUser(data, {timeout : 1000 * 50}), {
        onSuccess: (user) =>{
            window.location.href = createLink("userMe", user);
        },
        onError: (errors) =>{
            const errorStatusCodeApi = {
                409: "Email ou Login déjà utilisé",
                429: "Trop de tentatives, veuillez réessayer plus tard",
                400: errors?.message || "",
                "ECONNABORTED": "Nous avons rencontré un probleme, merci de renouveler d'ici quelques minutes"
            }
            setError({errorApi : errorStatusCodeApi[errors.statusCode] || ""});
        }
    });

    function _onSubmit(event){
        event.preventDefault();
        event.stopPropagation();
        const isFormValid = validateForm(user, setError);
        if (!isFormValid) return;

        mutateSignup({...user, context});
    }

    return (
        <form className={"signup-form"} onSubmit={(event) => _onSubmit(event)}>
            <Input 
                error={error.username || errorSignup?.errors?.username}
                placeholder={"Nom d'utilisateur"}
                autoComplete={"username"}
                type={"text"}  
                full
                value={user.username} 
                onChange={(event) => setUser({...user, username: event.target.value})}/>
            <Input 
                error={error.email || errorSignup?.errors?.email}
                placeholder={"Email"}
                type={"email"} 
                autoComplete={"email"}
                full
                value={user.email} 
                onChange={(event) => setUser({...user, email: event.target.value})}/>
            <Input 
                error={error.passwordStrengthError || errorSignup?.errors?.password}
                placeholder={"Mot de passe"}
                full
                type={"password"}  
                autoComplete={"new-password"}
                value={user.password} 
                showPasswordStrength
                onChange={(event) => setUser({...user, password: event.target.value})}/>
            {/* {!!user.password && <PasswordStrength password={user.password}/>} */}
            <SelectInput
                placeholder={"Genre"}
                error={error.gender || errorSignup?.errors?.gender}
                value={user.gender}
                options={genders.map((name, index) => ({label: name, value: index}))}
                onChange={(value) =>setUser({...user, gender : parseInt(value)})}
            />
            <Input 
                label={"Date de naissance"}
                type="date" 
                name="birthday"
                error={error.birthday1 || errorSignup?.errors?.birthday1} 
                onChange={(event) => {
                    // console.log(event.target.value);
                    setUser({...user, birthday1: event.target.value})
                }}
                full 
                min={minDate} 
                max={maxDate} />
            {isGenderCouple && (
                <Input 
                    label={"Date de naissance du conjoint"}
                    type="date" 
                    name="birthday2"
                    error={error.birthday2 || errorSignup?.errors?.birthday2} 
                    full 
                    onChange={(event) => {
                        console.log(event.target.value);
                        setUser({...user, birthday2: event.target.value})
                    }}
                    min={minDate} 
                    max={maxDate} />
            )}
            <div className='signup-check-cgu'>
                <input type={"checkbox"} id='cgu-ckeck' name='cgu' className={error.cguError ? "error" : ""} value={user.cgu} onChange={() => {
                    setUser({...user, cgu: !user.cgu})
                }}/>
                <label htmlFor='cgu-ckeck' className={"signup-form-cgu"}>J'ai lu et j'accepte <Link to="/cgus">les termes d'utilisation</Link></label>
                <TextError show={error.cgu} center>{error.cgu}</TextError>
            </div>
            <TextError style={{marginBottom:'12px'}} show={error.errorApi} center>{error.errorApi}</TextError>
            <Button variant={BUTTON_VARIANTS.REVERSE} full big type={"submit"} value={"Inscription"} loading={isLoadingSignup}>Créer mon compte</Button>
            
        </form>
    )
};


const SignupBase = ({
    handleClose,
    lockedReason,
    lockedContentOwner,
    lockedAction,
    context = {}
}) => {


    return (
        <div id="signup">
            {/* <div className="signup-title">Créer mon compte</div> */}
            <div className="signup-title">
                {SIGNUP_SUBTITLE_LOCKED_MAP[lockedAction] ? SIGNUP_SUBTITLE_LOCKED_MAP[lockedAction](lockedContentOwner) : SIGNUP_SUBTITLE_LOCKED_MAP.DEFAULT() }
            </div>
            <SignupFormulaire context={context}/>
        </div>
    )
};

export const Signup = ({
    toggle = false,
    handleClose = () =>{},
    lockedReason,
    lockedContentOwner,
    lockedAction,
    context = {}
 }) => {

    return (
        <Drawer
            open={toggle}
            handleClose={handleClose}
            small
        >
            <SignupBase
                lockedReason={lockedReason}
                lockedContentOwner={lockedContentOwner}
                lockedAction={lockedAction}
                handleClose={handleClose}
                context={context}
            />
        </Drawer>
    )
}

export const SignupPage = () =>{

    return(
        <Container small>
            <Helmet>
                <title>{config.preTitle}Inscription{config.postTitle}</title>
                <link rel="canonical" href={canonical(createLink("signup"), "", "signup")}/>
            </Helmet>
            <SignupBase />
        </Container>
    );
}

export default SignupPage;