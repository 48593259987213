import React from 'react';
import classNames from 'classnames';
import './Container.scss';

const Container = ({children, className, small, xsmall, medium, large, hasPadding, ...props }) => {
    const containerClasses = classNames('v3-container', className, {
        small,
        xsmall,
        medium,
        large,
        hasPadding
    });

    return (
        <div className={containerClasses} {...props}>
            {children}
        </div>
    );
};

export default React.memo(Container);
